import { useApolloClient, useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { Fragment, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Ad_ProcessGetForReportsPageDocument, ReportOutput } from '../../graphql/__generated__/graphql';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import { DBFilter, menuUuid, ProcessDB } from '../../models';
import { uiText } from '../../utils/Language';
import { runAndExportReport } from '../../utils/ReportUtil';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReportField from './ReportField';
import ReportWindow from './ReportWindow';

/**
 * Reports Component
 */
const reportFormat = {
	PDF: ReportOutput.Pdf,
	Excel: ReportOutput.Xlsx,
	HTML: ReportOutput.Html,
	CSV: ReportOutput.Csv,
} as const;

export type ReportFormValues = {
	AD_Process: { UU: string };
	AD_Process_ParaList: Array<{ UU: string; Name: string; Parameter: any | null; entity?: { UU: string | null } }>;
	formatType: ReportOutput;
};

const Report = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const previouslySavedParameterNamesAndValues = useRef<{
		[parameterName: string]: Pick<ReportFormValues['AD_Process_ParaList'][0], 'Parameter' | 'entity'> | undefined;
	}>({});

	const formMethods = useForm<ReportFormValues>({
		defaultValues: { AD_Process: { UU: '' }, AD_Process_ParaList: [], formatType: ReportOutput.Pdf },
	});
	const { fields: processParameters } = useFieldArray<ReportFormValues, 'AD_Process_ParaList', 'UU'>({
		control: formMethods.control,
		name: 'AD_Process_ParaList',
	});
	const watchedProcessParameters = formMethods.watch('AD_Process_ParaList') || [];
	const controlledProcessParameters = watchedProcessParameters.map((watchedField, index) => ({
		...processParameters[index],
		...watchedField,
	}));

	const { data: reports } = useQuery(Ad_ProcessGetForReportsPageDocument, {
		variables: {
			Sort: JSON.stringify([
				['name', 'asc'],
				['created', 'asc'],
			]),
			Filter: DBFilter<ProcessDB>()
				.nested('ad_menu')
				.nested('ad_treenodemm::ad_menu_id->node_id')
				.nested('ad_menu::parent_id->ad_menu_id')
				.property('ad_menu_uu')
				.equals(menuUuid.REPORTS_DROPDOWN)
				.up()
				.up()
				.up()
				.toString(),
		},
		fetchPolicy: 'cache-first',
	});

	// Generate the report
	const [{ value: reportResponse, loading: isProcessing }, onGenerateReport] = useCustomAsyncFn<
		SubmitHandler<ReportFormValues>
	>(
		async (formData) => {
			const reportToUse = reports?.AD_ProcessGet.Results.find((report) => report.UU === formData.AD_Process.UU);
			return await runAndExportReport(
				graphqlClient,
				formData.AD_Process.UU,
				formData.formatType,
				// For some reason, AD_Process_ParaList is sometimes NULL, though it never should be
				formData.AD_Process_ParaList?.map((parameter) => ({
					AD_Process: { UU: formData.AD_Process.UU },
					ParameterName:
						reportToUse?.AD_Process_ParaList?.find((processParameter) => processParameter.UU === parameter.UU)?.Name ||
						'',
					Parameter: parameter.entity?.UU || (parameter.Parameter === '' ? undefined : parameter.Parameter),
				})),
			);
		},
		[graphqlClient, reports],
	);

	const getValues = formMethods.getValues;
	const setValue = formMethods.setValue;
	const reset = formMethods.reset;
	const selectedReportUuid = formMethods.watch<'AD_Process.UU'>('AD_Process.UU');
	// When a different report is selected, we need to handle displaying it's parameters
	useUpdateEffect(() => {
		const currentParameters = getValues('AD_Process_ParaList');
		currentParameters.forEach((currentProcessParameterValue) => {
			previouslySavedParameterNamesAndValues.current[currentProcessParameterValue.Name] = {
				Parameter: currentProcessParameterValue.Parameter,
				entity: currentProcessParameterValue.entity,
			};
		});
		reset(
			{ AD_Process: { UU: selectedReportUuid }, AD_Process_ParaList: [], formatType: getValues('formatType') },
			{ keepIsSubmitted: false, keepDirty: false, keepTouched: false, keepIsValid: false, keepSubmitCount: false },
		);
		if (!selectedReportUuid) {
			return;
		}

		// get report
		const report = reports?.AD_ProcessGet.Results.find((report) => report.UU === selectedReportUuid);
		if (!report) {
			return;
		}

		// get report parameters
		if (!report.AD_Process_ParaList?.length) {
			return;
		}
		setValue(
			'AD_Process_ParaList',
			sortBy(report.AD_Process_ParaList, 'SeqNo').map((parameterWithSelectedValue) => {
				const existingParameter = previouslySavedParameterNamesAndValues.current[parameterWithSelectedValue.Name];
				return {
					UU: parameterWithSelectedValue.UU,
					Name: parameterWithSelectedValue.Name,
					Parameter: existingParameter?.Parameter || null,
					entity: { UU: existingParameter?.entity?.UU || null },
				};
			}),
		);
	}, [selectedReportUuid, reports, setValue, reset, getValues]);

	return (
		<Layout>
			<Layout.Header>
				<Layout.Title title={t(uiText.report.TITLE)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<Row className="bg-white ms-0">
					<LoadSpinner show={isProcessing} title={t(uiText.report.generate.CREATING)} />
					{reportResponse ? <ReportWindow response={reportResponse as Blob | undefined} /> : null}
					<FormProvider {...formMethods}>
						<Form autoComplete="off" className="px-0" onSubmit={formMethods.handleSubmit(onGenerateReport)}>
							<Card className="bh-card">
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="reportSelector">
											<Col xs={2} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.report.SELECT)}</Form.Label>
											</Col>
											<Col xs={7} className="d-flex align-items-center">
												<Form.Select {...formMethods.register('AD_Process.UU', { required: true })}>
													<option />
													{reports?.AD_ProcessGet.Results.map((report) => (
														<option key={report.UU} value={report.UU}>
															{report.Name}
														</option>
													))}
												</Form.Select>
											</Col>
										</Form.Group>
										<Col xs={3} />

										{controlledProcessParameters.map((field, index) => (
											<ReportField key={field.UU} field={field} index={index} />
										))}

										{!!selectedReportUuid && (
											<>
												<Form.Group as={Fragment} controlId="reportFormat">
													<Col xs={2} className="d-flex align-items-center">
														<Form.Label column>{t(uiText.report.FORMAT)}</Form.Label>
													</Col>
													<Col xs={7} className="d-flex align-items-center">
														<Form.Select {...formMethods.register('formatType')}>
															{Object.entries(reportFormat).map(([reportFormatTypeName, reportFormatTypeValue]) => (
																<option key={reportFormatTypeName} value={reportFormatTypeValue}>
																	{reportFormatTypeName}
																</option>
															))}
														</Form.Select>
													</Col>
												</Form.Group>
												<Col xs={3} />
											</>
										)}

										<Col xs={9} className="d-flex justify-content-end pt-3">
											<Button variant="success" type="submit" disabled={!selectedReportUuid}>
												{t(uiText.report.generate.GENERATE)}
											</Button>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Form>
					</FormProvider>
				</Row>
			</Layout.Body>
		</Layout>
	);
};

export default Report;
