import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import { ProductRefreshContext } from '../../contexts/ProductRefreshContext';
import UserContext from '../../contexts/UserContext';
import {
	C_TaxCategoryForProductsDocument,
	C_UomGetDefaultDocument,
	M_AttributeSetForProductPageDocument,
	M_InventorySaveForInitialProductStockDocument,
	M_PriceList_VersionGetForProductsDocument,
	M_PriceList_VersionGetForProductsQuery,
	M_ProductCategoriesForProductsDocument,
	M_ProductForProductEditingDocument,
	M_ProductForProductEditingQuery,
	M_ProductPriceInput,
	M_ProductSaveForProductDocument,
	M_StorageOnHandForInventoryListQuery,
	M_WarehousesForProductsPageDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useCustomAsyncFn from '../../hooks/useCustomAsyncFn';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import { C_TaxCategoryDB, DBFilter, DocAction, ProductCategoryDB, ProductCategoryType } from '../../models';
import { PriceListVersionDB } from '../../models/PriceListVersion';
import EntityFormProperties from '../../types/EntityFormProperties';
import { pageUuid } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getManualModelPriceMargin } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import { BHGraphQLTableProps } from '../BHTable/BHGraphQLTable';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import InventoryList from '../inventory-list/InventoryList';
import Layout from '../Layout/Layout';
import FyiModal from '../Modal/FyiModal';
import InitialStock, {
	constructInitialStockFormData,
	convertToInitialStockFormFields,
	InitailStockFormValues,
	primeDataForInitialStock,
} from './InitialStock';
import TransactionTable from './TransactionTable';

export type ProductFormProps = EntityFormProperties;

export type ProductFormValues = {
	UU: string;
	Name: string;
	bh_reorder_level: number | null;
	bh_reorder_quantity: number | null;
	Description: string | null;
	IsActive: boolean;
	LastPurchasePrice: number | null;
	M_Product_Category: {
		UU: string;
	};
	M_ProductPrice_Purchase: { UU: string; M_PriceList_Version: { UU: string }; PriceStd: number | null };
	M_ProductPrice_Sales: { UU: string; M_PriceList_Version: { UU: string }; PriceStd: number | null };
	TotalQuantity: number | null;
	needsExpirationAttributeSetInstance: boolean;
	submitEvent: '' | 'save' | 'saveAndClose' | 'saveAndNew';
} & InitailStockFormValues;

const convertToFormFields = (
	salesPriceListVersion: M_PriceList_VersionGetForProductsQuery['M_PriceList_VersionGet']['Results'][0],
	purchasePriceListVersion: M_PriceList_VersionGetForProductsQuery['M_PriceList_VersionGet']['Results'][0],
	initialData?: M_ProductForProductEditingQuery['M_Product'],
): ProductFormValues => {
	if (!initialData) {
		return {
			UU: v4(),
			bh_reorder_level: null,
			bh_reorder_quantity: null,
			Description: null,
			IsActive: true,
			LastPurchasePrice: null,
			M_Product_Category: {
				UU: '',
			},
			M_ProductPrice_Purchase: { UU: v4(), M_PriceList_Version: { UU: purchasePriceListVersion.UU }, PriceStd: null },
			M_ProductPrice_Sales: { UU: v4(), M_PriceList_Version: { UU: salesPriceListVersion.UU }, PriceStd: null },
			Name: '',
			needsExpirationAttributeSetInstance: false,
			TotalQuantity: null,
			submitEvent: '',
			...convertToInitialStockFormFields(),
		};
	}
	const existingPurchasePrice = initialData.M_ProductPrices?.find(
		(productPrice) => productPrice.M_PriceList_Version.UU === purchasePriceListVersion.UU,
	);
	const existingSalesPrice = initialData.M_ProductPrices?.find(
		(productPrice) => productPrice.M_PriceList_Version.UU === salesPriceListVersion.UU,
	);
	return {
		UU: initialData.UU,
		bh_reorder_level: initialData.bh_reorder_level || null,
		bh_reorder_quantity: initialData.bh_reorder_quantity || null,
		Description: initialData.Description || null,
		IsActive: initialData.IsActive,
		LastPurchasePrice: initialData.LastPurchasePrice || null,
		M_Product_Category: { UU: initialData.M_Product_Category.UU },
		M_ProductPrice_Purchase: {
			UU: existingPurchasePrice?.UU || v4(),
			M_PriceList_Version: { UU: purchasePriceListVersion.UU },
			PriceStd: existingPurchasePrice?.PriceStd || null,
		},
		M_ProductPrice_Sales: {
			UU: existingSalesPrice?.UU || v4(),
			M_PriceList_Version: { UU: salesPriceListVersion.UU },
			PriceStd: existingSalesPrice?.PriceStd || null,
		},
		TotalQuantity: initialData.TotalQuantity,
		Name: initialData.Name,
		needsExpirationAttributeSetInstance: initialData?.M_AttributeSet?.IsGuaranteeDate || false,
		submitEvent: '',
		...convertToInitialStockFormFields(),
	};
};

const getTitle = (uuid?: string) => (uuid ? uiText.product.title.UPDATE : uiText.product.title.NEW);

/**
 * Product Form definition
 * PS: This form contains no event/handler logic. It's only responsible for defining form fields.
 * @param props
 * @returns
 * @constructor
 */
const ProductForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: ProductFormProps) => {
	const graphqlClient = useApolloClient();
	const { organization, warehouse } = useContext(UserContext);

	const {
		data: [
			data,
			attributeSets,
			productCategories,
			warehouses,
			salesPriceListVersion,
			purchasePriceListVersion,
			defaultUnitOfMeasure,
			defaultTaxCategory,
		] = [],
	} = useSuspenseAsync(uuid || 'add-product', async () =>
		Promise.all([
			uuid
				? graphqlClient
						.query({
							query: M_ProductForProductEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
						.then((response) => response?.data.M_Product)
				: undefined,
			graphqlClient
				.query({
					query: M_AttributeSetForProductPageDocument,
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_AttributeSetGet.Results),
			graphqlClient
				.query({
					query: M_ProductCategoriesForProductsDocument,
					variables: {
						Filter: DBFilter<ProductCategoryDB>()
							.property('bh_product_category_type')
							.equals(ProductCategoryType.VALUE_PRODUCT)
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((category) => category.data.M_Product_CategoryGet.Results),
			graphqlClient
				.query({
					query: M_WarehousesForProductsPageDocument,
					variables: {
						Filter: getWarehouseByOrg(organization.UU).toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_WarehouseGet.Results),
			graphqlClient
				.query({
					query: M_PriceList_VersionGetForProductsDocument,
					variables: {
						Size: 1,
						Sort: JSON.stringify([
							['validfrom', 'desc'],
							['created', 'desc'],
						]),
						Filter: DBFilter<PriceListVersionDB>()
							.property('validfrom')
							.isLessThanOrEqualTo(startOfDay(new Date()))
							.nested('m_pricelist')
							.property('issopricelist')
							.equals(true)
							.property('isdefault')
							.equals(true)
							.property('isactive')
							.equals(true)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_PriceList_VersionGet.Results[0]),
			graphqlClient
				.query({
					query: M_PriceList_VersionGetForProductsDocument,
					variables: {
						Size: 1,
						Sort: JSON.stringify([
							['validfrom', 'desc'],
							['created', 'desc'],
						]),
						Filter: DBFilter<PriceListVersionDB>()
							.property('validfrom')
							.isLessThanOrEqualTo(startOfDay(new Date()))
							.nested('m_pricelist')
							.property('issopricelist')
							.equals(false)
							.property('isdefault')
							.equals(true)
							.property('isactive')
							.equals(true)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.M_PriceList_VersionGet.Results[0]),
			graphqlClient
				.query({ query: C_UomGetDefaultDocument, fetchPolicy: 'cache-first' })
				.then((response) => response.data.C_UOMGetDefault),
			graphqlClient
				.query({
					query: C_TaxCategoryForProductsDocument,
					variables: { Size: 1, Filter: DBFilter<C_TaxCategoryDB>().property('isdefault').equals(true).toString() },
					fetchPolicy: 'cache-first',
				})
				.then((response) => response.data.C_TaxCategoryGet.Results[0]),
			primeDataForInitialStock(graphqlClient),
		]),
	);

	const { t } = useTranslation();
	const [initialData, setInitialData] = useState(data);
	const formMethods = useForm<ProductFormValues>({
		defaultValues: convertToFormFields(salesPriceListVersion!, purchasePriceListVersion!, initialData),
	});
	const title = getTitle(!initialData ? undefined : initialData?.UU);

	const lastPurchasePrice = formMethods.watch('LastPurchasePrice') || 0;
	const enteredPurchasePrice = formMethods.watch('M_ProductPrice_Purchase.PriceStd') || 0;
	const buyPrice = initialData?.HasBeenPurchased ? lastPurchasePrice : enteredPurchasePrice;
	const sellPrice = formMethods.watch('M_ProductPrice_Sales.PriceStd') || 0;
	const totalQuantity = formMethods.watch('TotalQuantity') || 0;
	const isActive = formMethods.watch('IsActive');

	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.PRODUCTS);
	const [activeTab, setActiveTab] = useState('generalInformation');

	const setValue = formMethods.setValue;
	useEffect(() => {
		if (!isActive && totalQuantity > 0) {
			setValue('IsActive', true);
			FyiModal(t(uiText.product.userMessages.CANNOT_DEACTIVATE));
		}
	}, [isActive, totalQuantity, setValue, t]);
	useConfirmRefresh(formMethods.formState?.isDirty);

	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const reset = formMethods.reset;
	const [, onSubmit] = useCustomAsyncFn<SubmitHandler<ProductFormValues>>(
		async (formData) => {
			const attributeSet = attributeSets?.find((attributeSet) =>
				formData.needsExpirationAttributeSetInstance ? attributeSet.IsGuaranteeDate : !attributeSet.IsGuaranteeDate,
			);

			const formAction = formData.submitEvent;

			try {
				const productPricesToSave: M_ProductPriceInput[] = [
					{
						UU: formData.M_ProductPrice_Sales.UU,
						M_PriceList_Version: { UU: formData.M_ProductPrice_Sales.M_PriceList_Version.UU },
						M_Product: { UU: formData.UU },
						PriceLimit: formData.M_ProductPrice_Sales.PriceStd || 0,
						PriceList: formData.M_ProductPrice_Sales.PriceStd || 0,
						PriceStd: formData.M_ProductPrice_Sales.PriceStd || 0,
					},
				];
				// Purchase prices will only be updated if this is a new product
				if (!initialData || !initialData?.HasBeenPurchased) {
					productPricesToSave.push({
						UU: formData.M_ProductPrice_Purchase.UU,
						M_PriceList_Version: { UU: formData.M_ProductPrice_Purchase.M_PriceList_Version.UU },
						M_Product: { UU: formData.UU },
						PriceLimit: formData.M_ProductPrice_Purchase.PriceStd || 0,
						PriceList: formData.M_ProductPrice_Purchase.PriceStd || 0,
						PriceStd: formData.M_ProductPrice_Purchase.PriceStd || 0,
					});
				}
				const savedProduct = (
					await graphqlClient.mutate({
						mutation: M_ProductSaveForProductDocument,
						variables: {
							M_Product: {
								UU: formData.UU,
								Name: formData.Name,
								M_Product_Category: {
									UU: formData.M_Product_Category.UU,
								},
								BH_BuyPrice: initialData?.HasBeenPurchased ? undefined : formData.M_ProductPrice_Purchase.PriceStd || 0,
								bh_reorder_level: formData.bh_reorder_level,
								bh_reorder_quantity: formData.bh_reorder_quantity,
								BH_SellPrice: formData.M_ProductPrice_Sales.PriceStd || 0,
								C_TaxCategory: defaultTaxCategory?.UU ? { UU: defaultTaxCategory.UU } : undefined,
								C_UOM: defaultUnitOfMeasure?.UU ? { UU: defaultUnitOfMeasure.UU } : undefined,
								Description: formData.Description || null,
								IsActive: formData.IsActive,
								M_AttributeSet: { UU: attributeSet?.UU || '' },
							},
							M_ProductPrices: productPricesToSave,
						},
					})
				).data?.M_ProductSave;

				// If we don't have any initial data, we can save any initial quantities that may exist
				if (!initialData) {
					let [attributeSetInstancesToSave, inventoryToSave, inventoryLinesToSave] =
						await constructInitialStockFormData(formData, warehouse, graphqlClient, attributeSet);
					// If we have inventory lines, we should have inventory & ASIs
					if (inventoryLinesToSave.length > 0 && inventoryToSave && attributeSetInstancesToSave.length) {
						await graphqlClient.mutate({
							mutation: M_InventorySaveForInitialProductStockDocument,
							variables: {
								M_Inventory: inventoryToSave,
								M_AttributeSetInstances: attributeSetInstancesToSave,
								M_InventoryLines: inventoryLinesToSave,
								UU: inventoryToSave.UU!,
								DocumentAction: DocAction.COMPLETE,
							},
						});
					}
				}
				// Refresh the cache
				await graphqlClient.query({ query: M_ProductForProductEditingDocument, variables: { UU: formData.UU } });
				toast.success(t(uiText.product.userMessages.UPDATE_SUCCESS));

				if (formAction === 'saveAndNew') {
					reset(convertToFormFields(salesPriceListVersion!, purchasePriceListVersion!));
					setInitialData(undefined);
					dataWasSaved(savedProduct?.UU);
				} else if (formAction === 'saveAndClose') {
					onFinish(true, canSaveMany === false ? savedProduct?.UU : undefined);
				}
			} catch (error) {
				toast.error(t(uiText.product.error.SAVE_UPDATE, { error }));
			}
		},
		[
			reset,
			canSaveMany,
			attributeSets,
			warehouse,
			graphqlClient,
			initialData,
			salesPriceListVersion,
			purchasePriceListVersion,
		],
	);

	const stockTakeColumns = useMemo(() => {
		type ColumnType = BHGraphQLTableProps<
			M_StorageOnHandForInventoryListQuery['M_StorageOnHandGet']['Results'][0]
		>['columns'][0];
		let columns: ColumnType[] = [];
		if (warehouses && warehouses.length > 1) {
			columns.push({
				id: 'm_locator.m_warehouse.name',
				disableSortBy: true,
				Header: () => <div className={'React-table-header'}>{t(uiText.inventory.STOREROOM)}</div>,
				accessor: (d) => <div className="px-2">{d.M_Locator?.M_Warehouse.Name}</div>,
			} as ColumnType);
		}
		columns = [
			...columns,
			...[
				{
					id: 'm_attributesetinstance.guaranteedate',
					Header: () => (
						<div className={'React-table-header'}>{t(uiText.product.expirationDate.LABEL_ABBREVIATION)}</div>
					),
					accessor: (d) => (
						<div className="px-2">
							{!d.M_AttributeSetInstance.GuaranteeDate
								? ''
								: formatDate(new Date(d.M_AttributeSetInstance.GuaranteeDate))}
						</div>
					),
				} as ColumnType,
				{
					id: 'purchaseDate',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.RECEIVED_ON)}</div>,
					accessor: (d) => (
						<div className="px-2">
							{(d.M_AttributeSetInstance.PurchaseDate && formatDate(new Date(d.M_AttributeSetInstance.PurchaseDate))) ||
								''}
						</div>
					),
				} as ColumnType,
				{
					id: 'price',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.BUY_PRICE)}</div>,
					accessor: (d) => <div className="px-2 text-end">{formatNumber(d.M_AttributeSetInstance.PurchasePrice)}</div>,
				} as ColumnType,
				{
					id: 'qtyonhand',
					Header: () => <div className={'React-table-header'}>{t(uiText.product.quantity.LABEL)}</div>,
					accessor: (d) => <div className="px-2 text-end">{d.QtyOnHand}</div>,
				} as ColumnType,
			],
		];
		return columns;
	}, [t, warehouses]);

	const { willTrigger, triggerUpdate } = useTriggerUpdate();

	const dataUuid = !initialData ? undefined : initialData.UU;
	useUpdateEffect(() => {
		const fetchData = async () => {
			if (dataUuid) {
				let fetchedProduct = (
					await graphqlClient.query({
						query: M_ProductForProductEditingDocument,
						variables: { UU: dataUuid },
						fetchPolicy: 'network-only',
					})
				).data.M_Product;

				// Reset formData since updating the initialData will require a form reset for the updates to be displayed
				setInitialData(fetchedProduct);
				reset(convertToFormFields(salesPriceListVersion!, purchasePriceListVersion!, fetchedProduct));
			}
		};
		fetchData();
	}, [dataUuid, graphqlClient, willTrigger, reset, salesPriceListVersion, purchasePriceListVersion]);

	const inputs = (
		<ProductRefreshContext.Provider value={{ willTrigger, triggerUpdate }}>
			<FormProvider {...formMethods}>
				<Form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off" className="px-0">
					<fieldset disabled={disableWrite}>
						<Card className="bh-card">
							<Card.Body>
								<Row className="gy-3">
									<Form.Group as={Fragment} controlId="name">
										<Col xs={1} className="d-flex align-items-center">
											<Form.Label column>{t(uiText.product.labels.NAME)}</Form.Label>
										</Col>
										<Col xs={5} className="d-flex align-items-center">
											<Form.Control
												placeholder={t(uiText.product.labels.ENTER_PRODUCT_NAME)}
												{...formMethods.register('Name', { required: true })}
											/>
											{formMethods.formState?.errors?.Name && (
												<span className="text-danger">{t(uiText.product.validationMessages.REQUIRE_PRODUCT_NAME)}</span>
											)}
										</Col>
									</Form.Group>
									<Form.Group as={Fragment} controlId="category">
										<Col xs={1} className="d-flex align-items-center">
											<Form.Label column>{t(uiText.product.labels.CATEGORY)}</Form.Label>
										</Col>
										<Col xs={5} className="d-flex align-items-center">
											<DynamicSelect
												{...formMethods.register('M_Product_Category.UU', {
													required: true,
													pattern: new RegExp(`^((?!.*${t(uiText.product.labels.SELECT_PRODUCT_CATEGORY)}*).)*$`),
												})}
												isLoading={!productCategories?.length}
											>
												<option>{t(uiText.product.labels.SELECT_PRODUCT_CATEGORY)}</option>
												{productCategories?.map((productCategory) => (
													<option key={productCategory.UU} value={productCategory.UU}>
														{productCategory.Name}
													</option>
												))}
											</DynamicSelect>
											{formMethods.formState?.errors?.M_Product_Category?.UU && (
												<span className="text-danger">
													{t(uiText.product.validationMessages.REQUIRE_CATEGORY_NAME)}
												</span>
											)}
										</Col>
									</Form.Group>
								</Row>
							</Card.Body>
						</Card>
					</fieldset>
					<Tabs
						activeKey={activeTab}
						className="pt-2 mx-n2_5"
						onSelect={(selectedTab) => selectedTab && setActiveTab(selectedTab)}
					>
						<Tab eventKey="generalInformation" title={t(uiText.product.tabs.GENERAL_INFORMATION)} className="px-2_5">
							<fieldset disabled={disableWrite}>
								<Card className="bh-card">
									<Card.Body>
										<Row className="gy-3">
											<Form.Group as={Fragment} controlId="buyPrice">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.UNIT_BUY_PRICE)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													{initialData?.HasBeenPurchased ? (
														<fieldset disabled className="w-100">
															<Controller<ProductFormValues, 'LastPurchasePrice'>
																name="LastPurchasePrice"
																render={({ field }) => <FormatNumberInput step="0.01" {...field} />}
															/>
														</fieldset>
													) : (
														<Controller<ProductFormValues, 'M_ProductPrice_Purchase.PriceStd'>
															name="M_ProductPrice_Purchase.PriceStd"
															render={({ field }) => <FormatNumberInput step="0.01" {...field} />}
														/>
													)}
												</Col>
											</Form.Group>
											<Form.Group as={Fragment} controlId="sellPrice">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.UNIT_SELL_PRICE)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													<Controller<ProductFormValues, 'M_ProductPrice_Sales.PriceStd'>
														name="M_ProductPrice_Sales.PriceStd"
														render={({ field }) => <FormatNumberInput step="0.01" {...field} />}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Fragment} controlId="priceMargin">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.PRICE_MARGIN)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													<FormatNumberInput value={getManualModelPriceMargin(buyPrice, sellPrice)} readOnly={true} />
												</Col>
											</Form.Group>

											<Form.Group as={Fragment} controlId="reorderLevel">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.REORDER_LEVEL)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													<Controller<ProductFormValues, 'bh_reorder_level'>
														name="bh_reorder_level"
														render={({ field }) => (
															<FormatNumberInput
																placeholder={t(uiText.product.labels.ENTER_REORDER_LEVEL)}
																{...field}
															/>
														)}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Fragment} controlId="reorderQuantity">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.REORDER_QUANTITY)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													<Controller<ProductFormValues, 'bh_reorder_quantity'>
														name="bh_reorder_quantity"
														render={({ field }) => (
															<FormatNumberInput
																placeholder={t(uiText.product.labels.ENTER_REORDER_QUANTITY)}
																{...field}
															/>
														)}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Fragment} controlId="totalQuantity">
												<Col xs={1} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.product.labels.CURRENT_QUANTITY)}</Form.Label>
												</Col>
												<Col xs={3} className="d-flex align-items-center">
													<FormatNumberInput readOnly value={totalQuantity} />
												</Col>
											</Form.Group>

											<Form.Group as={Fragment} controlId="description">
												<Col xs={1}>
													<Form.Label column>{t(uiText.product.labels.DESCRIPTION)}</Form.Label>
												</Col>
												<Col xs={11} className="d-flex align-items-center">
													<Form.Control
														as="textarea"
														rows={3}
														placeholder={t(uiText.product.labels.ENTER_DESCRIPTION)}
														{...formMethods.register('Description')}
													/>
												</Col>
											</Form.Group>

											<Form.Group as={Col} controlId="needsExpirationAttributeSetInstance" xs={{ span: 2, offset: 1 }}>
												<Form.Check
													label={t(uiText.product.labels.PRODUCT_EXPIRES)}
													{...formMethods.register('needsExpirationAttributeSetInstance')}
												/>
											</Form.Group>
											<Form.Group as={Col} controlId="isActive" xs={2}>
												<fieldset disabled={!initialData || disableDeactivate}>
													<Form.Check label={t(uiText.product.button.ACTIVE)} {...formMethods.register('IsActive')} />
												</fieldset>
											</Form.Group>
										</Row>
									</Card.Body>
								</Card>
							</fieldset>
							{!initialData && <InitialStock />}
						</Tab>
						<Tab
							eventKey="inventoryTransactions"
							title={t(uiText.product.tabs.INVENTORY_TRANSACTIONS)}
							className="px-2_5"
							disabled={!initialData}
						>
							<Card className="bh-card">
								<Card.Body>
									<Row className="gy-3">{initialData && <TransactionTable productUuid={initialData.UU} />}</Row>
								</Card.Body>
							</Card>
						</Tab>
						<Tab
							disabled={!initialData}
							eventKey="currentInventory"
							title={t(uiText.product.tabs.CURRENT_INVENTORY)}
							className="px-2_5"
						>
							<Card className="bh-card">
								<Card.Body className={'mt-2'}>
									<Row className="gy-3">
										<InventoryList
											columns={stockTakeColumns}
											productUuid={initialData?.UU}
											className={'bh-table--form mt-2 mb-3'}
											paginationClassName={'px-0 py-1'}
										/>
									</Row>
								</Card.Body>
							</Card>
						</Tab>
					</Tabs>
				</Form>
			</FormProvider>
		</ProductRefreshContext.Provider>
	);

	const buttons =
		activeTab === 'generalInformation' ? (
			<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
				{disableWrite ? (
					<Col xs="auto">
						<BasicButton
							name={uiText.product.button.BACK}
							text={t(uiText.product.button.BACK)}
							variant="danger"
							icon="arrow-left"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
				) : (
					<>
						<Col xs="auto">
							<BasicButton
								name={uiText.product.button.CANCEL}
								text={t(uiText.product.button.CANCEL)}
								variant="danger"
								icon="times"
								active={true}
								onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
							/>
						</Col>
						<Col xs="auto" className="ms-auto">
							{canSaveMany === false ? (
								<BasicButton
									name={uiText.product.button.SAVE_AND_CLOSE}
									text={t(uiText.product.button.SAVE_AND_CLOSE)}
									variant="success"
									icon="save"
									active
									onClick={() => {
										formMethods.setValue('submitEvent', 'saveAndClose');
										formMethods.handleSubmit(onSubmit)();
									}}
								/>
							) : (
								<BHDropdownButton title={t(uiText.patient.button.SAVE)} icon="check" variant="success">
									<Dropdown.Item
										onClick={() => {
											formMethods.setValue('submitEvent', 'saveAndClose');
											formMethods.handleSubmit(onSubmit)();
										}}
									>
										<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
										{t(uiText.product.button.SAVE_AND_CLOSE)}
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											formMethods.setValue('submitEvent', 'saveAndNew');
											formMethods.handleSubmit(onSubmit)();
										}}
									>
										<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
										{t(uiText.product.button.SAVE_AND_NEW_PRODUCT)}
									</Dropdown.Item>
								</BHDropdownButton>
							)}
						</Col>
					</>
				)}
			</Row>
		) : (
			<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
				{disableWrite ? (
					<Col xs="auto">
						<BasicButton
							name={uiText.product.button.BACK}
							text={t(uiText.product.button.BACK)}
							variant="danger"
							icon="arrow-left"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
				) : (
					<Col xs="auto">
						<BasicButton
							name={uiText.product.button.CANCEL}
							text={t(uiText.product.button.CANCEL)}
							variant="danger"
							icon="times"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
				)}
			</Row>
		);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="bg-white pb-0_5 me-n2_5">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspenseWrapper<ProductFormProps>({ loadingLabel: uiText.product.LOADING_TEXT, getTitle })(
	ProductForm,
);
