import { useApolloClient } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
	Bh_ConceptDiagnosisListPageGetDocument,
	Bh_ConceptDiagnosisListPageGetQuery,
} from '../../graphql/__generated__/graphql';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import { ConceptExtraKey } from '../../models';
import ListPageState from '../../models/ListPageState';
import { exception } from '../../utils/analytics';
import { getCodedDiagnosisDefaultSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getCodedDiagnosisIcd10Code } from '../../utils/ModelUtils';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import CodedDiagnosisForm from './CodedDiagnosisForm';

const CodedDiagnosis = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<Bh_ConceptDiagnosisListPageGetQuery['BH_ConceptGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: Bh_ConceptDiagnosisListPageGetDocument,
							variables,
							fetchPolicy: 'network-only',
						})
					).data.BH_ConceptGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.codedDiagnosis.error.COULD_NOT_LOAD));
					}
					exception({ description: `Coded Diagnosis fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			sorted: JSON.stringify([
				['bh_display_name', 'asc'],
				['created', 'asc'],
			]),
		},
	);

	// Handle searching and filtering
	useEffect(() => {
		onFilterUpdate(getCodedDiagnosisDefaultSearchFilter(searchText).toString());
	}, [searchText, onFilterUpdate]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		setSearchText('');
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.codedDiagnosis.title.LIST)}
							showRefreshIcon
							onRefresh={() => refresh({ resetPage: true })}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search initialText={searchText} onSearch={setSearchText} />
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<Bh_ConceptDiagnosisListPageGetQuery['BH_ConceptGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'BH_Display_Name',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.CIEL_NAME)}</div>
										),
										accessor: 'BH_Display_Name',
									},
									{
										id: 'bh_icd10who',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.ICD10_WHO)}</div>
										),
										accessor: (data) => getCodedDiagnosisIcd10Code(data) || '',
										disableSortBy: true,
									},
									{
										id: 'index_terms',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.codedDiagnosis.labels.SEARCH_TERMS)}</div>
										),
										accessor: (data) => {
											return (
												data.BH_Concept_Extras?.find(
													(extra) => (extra.BH_Key || '').toLowerCase() === ConceptExtraKey.INDEX_TERMS.toLowerCase(),
												)?.BH_Value || ''
											);
										},
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.codedDiagnosis.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<CodedDiagnosisForm
					uuid={selectedUuid}
					onFinish={(refreshData?: boolean) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default CodedDiagnosis;
